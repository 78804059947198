// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { storage, getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { doc, setDoc, getFirestore} from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
const {APIKEY, AUTHDOMAIN, PROJECTID, STORAGEBUCKET, MESSAGINGSENDERID, APPID} = process.env;
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: APIKEY,
    authDomain: AUTHDOMAIN,
    projectId: PROJECTID,
    storageBucket: STORAGEBUCKET,
    messagingSenderId: MESSAGINGSENDERID,
    appId: APPID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore();

const uploadButton = document.querySelector('#uploadImage');
const checkbox = document.querySelector('#dataProtection');

uploadButton.addEventListener('click', (e) => {
    e.preventDefault();
    document.querySelector('.errorMessage').classList.add('displayNone');
    document.querySelector('.errorMessageFilter').classList.add('displayNone');
    // check if checkbox is checked
    if(!checkbox.checked) {
        document.querySelector('.errorMessage').classList.remove('displayNone');
        return false;
    }
    // check if filter is selected
    if(document.querySelector('#frameSelected').value === '') {
        document.querySelector('.errorMessageFilter').classList.remove('displayNone');
        return false;
    }

    // Update Button to Loading
    console.log(uploadButton.querySelector('img').src);
    uploadButton.querySelector('img').src = require("./icons/uploading.svg");
    uploadButton.querySelector('img').classList.add('uploadingAnimation')
    uploadButton.querySelector('span').innerText = "Uploading";

    const canvas = document.querySelector('#canvas');
    const storage = getStorage();
    const imageRef = ref(storage, `image-${uuidv4()}`);
    const dataURL = canvas.toDataURL();
    uploadString(imageRef, dataURL, 'data_url').then(snapshot => {

        uploadButton.querySelector('img').src = require("./icons/uploadComplete.svg");
        uploadButton.querySelector('img').classList.remove('uploadingAnimation')
        uploadButton.querySelector('span').innerText = "Upload complete";
        setTimeout(() => {
            uploadButton.classList.add('displayNone');
            uploadButton.querySelector('img').src = require("./icons/upload.svg");
            uploadButton.querySelector('span').innerText = "Submit";
            getDownloadURL(imageRef).then(downloadURL => {
                document.querySelector('#downloadImage').href = downloadURL
                setDoc(doc(db, "LivePhotoURLs", uuidv4()), {
                    url: downloadURL
                })
            });
            document.querySelector('#downloadImage').classList.remove('displayNone');
        }, 500);

    }, (error) => {
        console.log(error);
    })
})